import React, { useState, useEffect } from 'react'
import { ScrollView, StyleSheet, Text, View, TextInput, TouchableOpacity } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { useQuery } from '@apollo/client'
import { Switch } from 'react-native-switch-ios'
import { Ionicons } from '@expo/vector-icons'
import { Calendar, LocaleConfig } from 'react-native-calendars'
import Select from 'react-select'
import IMask from 'imask'

import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import customParseFormat from 'dayjs/plugin/customParseFormat'

import { FIND_MANY_COMPANY } from '../gqls'

import Window from '../components/Window'

dayjs.locale('ru')
dayjs.extend(customParseFormat)

LocaleConfig.locales['ru'] = {
    monthNames: [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь'
    ],
    monthNamesShort: [
        'Янв',
        'Февр',
        'Март',
        'Апр',
        'Май',
        'Июнь',
        'Июль',
        'Авг',
        'Сент',
        'Окт',
        'Нояб',
        'Дек'
    ],
    dayNames: [
        'Воскресенье',
        'Понедельник',
        'Вторник',
        'Среда',
        'Четверг',
        'Пятница',
        'Суббота'
    ],
    dayNamesShort: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    today: 'Сегодня'
}

LocaleConfig.defaultLocale = 'ru'

const sorts = [
    {
        label: 'По рейтингу',
        value: 'rate'
    },
    {
        label: 'По цене',
        value: 'cost'
    },
    {
        label: 'По гостям (по возрастанию)',
        value: 'guestMax'
    },
    {
        label: 'По гостям (по убыванию)',
        value: 'guestMin'
    },
]

const places = [
    {
        label: 'г. Якутск',
        value: 'yakutsk'
    },
    {
        label: 'г. Якутск, Вилюйский тракт',
        value: 'vilyuyskiy'
    },
    {
        label: 'г. Якутск, Маганский тракт',
        value: 'maganskiy'
    },
    {
        label: 'г. Якутск, Намский тракт',
        value: 'namskiy'
    },
    {
        label: 'г. Якутск, Намцырский тракт',
        value: 'namtsyrskiy'
    },
    {
        label: 'г. Якутск, Покровский тракт',
        value: 'pokrovskiy'
    },
    {
        label: 'г. Якутск, Хатын-Юряхское шоссе',
        value: 'khatyn-yuryakhskoye'
    },
    {
        label: 'г. Якутск, Сергеляхское шоссе',
        value: 'sergelyakhskoye'
    },
    {
        label: 'г. Якутск, мкр. Кангалассы',
        value: 'khangalassy'
    },
    {
        label: 'г. Якутск, с. Пригородный',
        value: 'prigorodniy'
    },
    {
        label: 'г. Якутск, с. Старая Табага',
        value: 'tabaga'
    },
    {
        label: 'г. Якутск, с. Хатассы',
        value: 'khatassy'
    },
    {
        label: 'Амгинский район',
        value: 'amginskiy-rayon'
    },
    {
        label: 'Горный район',
        value: 'gorny-rayon'
    },
    {
        label: 'Мегино-Кангаласский район',
        value: 'megino-kangalasskiy-rayon'
    },
    {
        label: 'Намский район',
        value: 'namskiy-rayon'
    },
    {
        label: 'Нерюнгринский район',
        value: 'nerungrinskiy-rayon'
    },
    {
        label: 'Хангаласский район',
        value: 'khangalasskiy-rayon'
    }
]

const comforts = [
    {
        label: 'Мангальная зона',
        value: 'grill'
    },
    {
        label: 'Баня/парилка/сауна',
        value: 'bathhouse'
    },
    {
        label: 'Гараж',
        value: 'garage'
    },
    {
        label: 'Портативный гараж',
        value: 'portable garage'
    },
    {
        label: 'Детская площадка',
        value: 'playground'
    },
    {
        label: 'Беседка',
        value: 'gazebo'
    },
    {
        label: 'Бассейн',
        value: 'pool'
    },
    {
        label: 'Рядом с рекой/озером',
        value: 'river'
    },
    {
        label: 'Трансфер',
        value: 'transfer'
    },
    {
        label: 'Можно с питомцами',
        value: 'pet'
    },
    {
        label: 'Кондиционер',
        value: 'conditioner'
    },
    {
        label: 'Wi-Fi',
        value: 'wifi'
    },
    {
        label: 'Санузел',
        value: 'bathroom'
    },
    {
        label: 'Плита для варки',
        value: 'stove'
    },
    {
        label: 'Холодильник',
        value: 'fridge'
    },
    {
        label: 'Микроволновая печь',
        value: 'microwave'
    },
    {
        label: 'Посуда/приборы',
        value: 'dishes'
    },
    {
        label: 'Питьевая вода',
        value: 'water'
    },
    {
        label: 'Караоке',
        value: 'karaoke'
    },
    {
        label: 'Телевизор',
        value: 'tv'
    },
    {
        label: 'Проектор',
        value: 'projector'
    },
    {
        label: 'Игровая зона в доме',
        value: 'gamezone'
    },
    {
        label: 'Постельное белье',
        value: 'bedding'
    },
    {
        label: 'Завтрак включен',
        value: 'breakfast'
    },
]

const screens = {
    objects: 'Objects',
    tours: 'Tours',
    feeds: 'Feeds'
}

const numberMask = IMask.createMask({
    mask: Number
})

const FilterScreen = ({ route }) => {
    const { type } = route.params
    const navigation = useNavigation()
    const now = dayjs()

    const [from, setFrom] = useState('')
    const [to, setTo] = useState('')
    const [date, setDate] = useState('')
    const [people, setPeople] = useState('')
    const [search, setSearch] = useState('')
    const [place, setPlace] = useState('')
    const [comfort, setComfort] = useState('')
    const [sort, setSort] = useState('')
    const [companies, setCompanies] = useState([])
    const [company, setCompany] = useState('')
    const [bed, setBed] = useState('')
    const [heat, setHeat] = useState(false)

    const [currentDate, setCurrentDate] = useState(null)
    const [month, setMonth] = useState(parseInt(now.format('MM')))
    const [year, setYear] = useState(now.format('YYYY'))

    const [monthDates, setMonthDates] = useState([])

    const [selectedDate, setSelectedDate] = useState(null)
    const [picker, setPicker] = useState(false)

    useQuery(FIND_MANY_COMPANY, {
        fetchPolicy: 'network-only',
        variables: {
            where: {
                objects: {
                    every: {
                        publish: {
                            equals: true
                        }
                    }
                }
            }
        },
        onCompleted: ({ findManyCompany }) => {
            const { params } = route
            const companiesData = findManyCompany.map(object => ({
                label: object.name,
                value: object.id
            }))
            setCompanies(companiesData)
            if (params.company) {
                const paramsCompaines = JSON.parse(params.company)
                const fitlerCompaines = companiesData.filter(object => paramsCompaines.includes(object.value))
                setCompany(fitlerCompaines)
            }
        }
    })

    useEffect(() => {
        const { params } = route
        if (params.from) setFrom(params.from)
        if (params.to) setTo(params.to)
        if (params.date) {
            const reserveDay = dayjs(params.date, 'DD.MM.YYYY').format('YYYY-MM-DD')
            setSelectedDate(reserveDay)
            setCurrentDate(reserveDay)
            setDate(params.date)
        } else {
            setCurrentDate(now.format('YYYY-MM-DD'))
        }
        if (params.people) setPeople(params.people)
        if (params.search) setSearch(params.search)
        if (params.bed) setBed(params.bed)
        if (params.heat) setHeat(params.heat)
        if (params.place) setPlace(places.find(object => object.value === params.place))
        if (params.comfort) {
            const paramsComforts = JSON.parse(params.comfort)
            const fitlerComforts = comforts.filter(object => paramsComforts.includes(object.value))
            setComfort(fitlerComforts)
        }
        if (params.sort) setSort(sorts.find(object => object.value === params.sort))
    }, [])

    useEffect(() => {
        const dates = new Array(dayjs(`${year}-${month}-01`, { format: 'YYYY-MM-DD' }).daysInMonth()).fill(null).map((x, i) => dayjs(`${year}-${month}-01`, { format: 'YYYY-MM-DD' }).startOf('month').add(i, 'days').format('YYYY-MM-DD'))
        setMonthDates(dates)
    }, [month, year])


    if (!type) return null

    const disableSchedule = monthDates.reduce((acc, current) => {
        if (now.format('YYYY-MM-DD') === current) {
            return acc
        }
        if (now.isAfter(current)) {
            acc[current] = {
                disabled: true,
                disableTouchEvent: true
            }
        }
        return acc
    }, {})

    const onSubmit = () => {
        const screenName = screens[type];
        const params = {
            from: from || undefined,
            to: to || undefined,
            date: date || undefined,
            people: people || undefined,
            search: search || undefined,
            place: place?.value || undefined,
            bed: bed || undefined,
            heat: heat || undefined,
            comfort: comfort && comfort.length > 0 ? JSON.stringify(comfort.map(object => object.value)) : undefined,
            company: company && company.length > 0 ? JSON.stringify(company.map(object => object.value)) : undefined,
            sort: sort?.value || undefined
        }
        const stackName = `${type.charAt(0).toUpperCase()}${type.slice(1)}Stack`;

        navigation.navigate(stackName, {
            screen: screenName,
            params: params,
        });
}

    const onReset = () => {
        setFrom('')
        setTo('')
        setDate('')
        setPeople('')
        setSearch('')
        setPlace('')
        setComfort('')
        setCompany('')
        setSort('')
        setBed('')
        setHeat(false)
    }

    const onChangeFrom = (value) => {
        const maskedValue = numberMask.resolve(value)
        setFrom(maskedValue)
    }

    const onChangeTo = (value) => {
        const maskedValue = numberMask.resolve(value)
        setTo(maskedValue)
    }

    const onChangeDate = (value) => {
        setDate(value)
    }

    const onChangePeople = (value) => {
        const maskedValue = numberMask.resolve(value)
        setPeople(maskedValue)
    }

    const onChangeBed = (value) => {
        const maskedValue = numberMask.resolve(value)
        setBed(maskedValue)
    }

    const onShowPicker = () => {
        setPicker(prev => !prev)
    }

    const onClear = () => {
        setSelectedDate(null)
        setDate('')
    }

    if (type === 'objects' && currentDate) {
        return (
            <>
                <ScrollView showsVerticalScrollIndicator={false} style={styles.container} contentContainerStyle={{ marginTop: 16 }}>
                    <Window style={{ paddingHorizontal: 16 }}>
                        <Text style={styles.title}>Дата</Text>
                        <View>
                            <TouchableOpacity activeOpacity={1} onPress={onShowPicker} style={[styles.input, { alignItems: 'center', justifyContent: 'space-between' }]} >
                                {
                                    date ? (
                                        <>
                                            <Text>{date}</Text>
                                            <TouchableOpacity onPress={onClear}>
                                                <Ionicons color='#667085' size={20} name='close' />
                                            </TouchableOpacity>
                                        </>
                                    ) : <Text style={{ color: '#667085' }}>Выберите дату</Text>
                                }
                            </TouchableOpacity>
                            {
                                picker ? (
                                    <Calendar
                                        firstDay={1}
                                        current={currentDate}
                                        theme={{
                                            selectedDayBackgroundColor: '#BCC8B2',
                                            todayTextColor: '#000'
                                        }}
                                        renderArrow={(direction) => <Ionicons size={20} name={direction === 'left' ? 'ios-chevron-back' : 'ios-chevron-forward'} />}
                                        onDayPress={day => {
                                            const now = dayjs()
                                            if (now.isBefore(day.dateString) || now.isSame(day.dateString, 'day')) {
                                                setSelectedDate(day.dateString)
                                                setCurrentDate(day.dateString)
                                                setPicker(false)
                                                onChangeDate(dayjs(day.dateString).format('DD.MM.YYYY'))
                                            }
                                        }}
                                        markedDates={{
                                            ...disableSchedule,
                                            [selectedDate]: { selected: true, disableTouchEvent: false, selectedDotColor: 'orange' }
                                        }}
                                        onMonthChange={(date) => {
                                            setMonth(date.month)
                                            setYear(date.year)
                                        }}
                                    />
                                ) : null
                            }
                        </View>
                        <View style={styles.line} />
                        <Text style={styles.title}>Сортировка</Text>
                        <Select
                            className='select'
                            styles={{
                                control: (baseStyles) => ({
                                    ...baseStyles,
                                    height: 44,
                                    borderWidth: 1,
                                    borderColor: '#D0D5DD',
                                    borderRadius: 4,
                                    paddingHorizontal: 10,
                                    boxShadow: 'none',
                                    '&:hover': {
                                        border: '1 !important'
                                    }
                                }),
                                option: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: state.isSelected ? '#BCC8B2' : 'transparent'
                                }),
                                menu: (baseStyles) => ({
                                    ...baseStyles,
                                    width: 'max-content',
                                    minWidth: '99%',
                                    marginLeft: '0.5%'
                                })
                            }}
                            placeholder='Выберите сортировку'
                            options={sorts}
                            onChange={(value) => {
                                setSort(value)
                            }}
                            value={sort}
                        />
                        <View style={styles.line} />
                        <Text style={styles.title}>По наименованию обьекта</Text>
                        <Select
                            className='select'
                            isMulti
                            styles={{
                                control: (baseStyles) => ({
                                    ...baseStyles,
                                    minHeight: 44,
                                    borderWidth: 1,
                                    borderColor: '#D0D5DD',
                                    borderRadius: 4,
                                    paddingHorizontal: 10,
                                    boxShadow: 'none',
                                    '&:hover': {
                                        border: '1 !important'
                                    }
                                }),
                                option: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: state.isSelected ? '#BCC8B2' : 'transparent',
                                }),
                                menu: (baseStyles) => ({
                                    ...baseStyles,
                                    width: 'max-content',
                                    minWidth: '99%',
                                    marginLeft: '0.5%'
                                })
                            }}
                            placeholder='Выберите обьекты'
                            options={companies}
                            onChange={(value) => {
                                setCompany(value)
                            }}
                            value={company}
                        />
                        <View style={styles.line} />
                        <Text style={styles.title}>Цена</Text>
                        <View style={styles.input}>
                            <TextInput onChangeText={onChangeFrom} value={from} style={{ flex: 1 }} placeholder='От' placeholderTextColor='#667085' />
                            <TextInput onChangeText={onChangeTo} value={to} style={{ flex: 1 }} placeholder='До' placeholderTextColor='#667085' />
                        </View>
                        <View style={styles.line} />
                        <Text style={styles.title}>Количество гостей</Text>
                        <TextInput onChangeText={onChangePeople} value={people} style={styles.input} placeholder='Введите количество' placeholderTextColor='#667085' />
                        <View style={styles.line} />
                        <Text style={styles.title}>Количество спальных мест</Text>
                        <TextInput onChangeText={onChangeBed} value={bed} style={styles.input} placeholder='Введите количество' placeholderTextColor='#667085' />
                        <View style={styles.line} />
                        <Text style={[styles.title, { marginBottom: 12 }]}>С отоплением</Text>
                        <Switch
                            inactiveBgColor='#D0D5DD'
                            activeBgColor='#101828'
                            isOn={heat}
                            onToggle={() => setHeat(prev => !prev)}
                        />
                        <View style={styles.line} />
                        <Text style={styles.title}>Расположение</Text>
                        <Select
                            className='select'
                            styles={{
                                control: (baseStyles) => ({
                                    ...baseStyles,
                                    height: 44,
                                    borderWidth: 1,
                                    borderColor: '#D0D5DD',
                                    borderRadius: 4,
                                    paddingHorizontal: 10,
                                    boxShadow: 'none',
                                    '&:hover': {
                                        border: '1 !important'
                                    }
                                }),
                                option: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: state.isSelected ? '#BCC8B2' : 'transparent',
                                }),
                                menu: (baseStyles) => ({
                                    ...baseStyles,
                                    width: 'max-content',
                                    minWidth: '99%',
                                    marginLeft: '0.5%'
                                })
                            }}
                            placeholder='Выберите расположение'
                            options={places}
                            onChange={(value) => {
                                setPlace(value)
                            }}
                            value={place}
                        />
                        <View style={styles.line} />
                        <Text style={styles.title}>Удобства</Text>
                        <Select
                            className='select'
                            isMulti
                            styles={{
                                control: (baseStyles) => ({
                                    ...baseStyles,
                                    minHeight: 44,
                                    borderWidth: 1,
                                    borderColor: '#D0D5DD',
                                    borderRadius: 4,
                                    paddingHorizontal: 10,
                                    boxShadow: 'none',
                                    '&:hover': {
                                        border: '1 !important'
                                    },
                                }),
                                option: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: state.isSelected ? '#BCC8B2' : 'transparent',
                                }),
                                menu: (baseStyles) => ({
                                    ...baseStyles,
                                    width: 'max-content',
                                    minWidth: '99%',
                                    marginLeft: '0.5%'
                                })
                            }}
                            placeholder='Выберите удобства'
                            options={comforts}
                            onChange={(value) => {
                                setComfort(value)
                            }}
                            value={comfort}
                        />
                        <View style={styles.line} />
                    </Window>
                </ScrollView>
                <Window>
                    <View style={styles.footer}>
                        <TouchableOpacity onPress={onReset} style={styles.clearButton}>
                            <Text style={styles.clearButtonText}>Сбросить</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={onSubmit} style={styles.button}>
                            <Text style={styles.buttonText}>Применить</Text>
                        </TouchableOpacity>
                    </View>
                </Window>
            </>
        )
    }

    if (type === 'tours') {
        return (
            <>
                <ScrollView showsVerticalScrollIndicator={false} style={styles.container} contentContainerStyle={{ marginTop: 16 }}>
                    <Window style={{ paddingHorizontal: 16 }}>
                        <Text style={styles.title}>Цена</Text>
                        <View style={styles.input}>
                            <TextInput onChangeText={onChangeFrom} value={from} style={{ flex: 1 }} placeholder='От' placeholderTextColor='#667085' />
                            <TextInput onChangeText={onChangeTo} value={to} style={{ flex: 1 }} placeholder='До' placeholderTextColor='#667085' />
                        </View>
                    </Window>
                </ScrollView>
                <Window>
                    <View style={styles.footer}>
                        <TouchableOpacity onPress={onReset} style={styles.clearButton}>
                            <Text style={styles.clearButtonText}>Сбросить</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={onSubmit} style={styles.button}>
                            <Text style={styles.buttonText}>Применить</Text>
                        </TouchableOpacity>
                    </View>
                </Window>
            </>
        )
    }

    if (type === 'feeds') {
        return (
            <>
                <ScrollView style={styles.container} contentContainerStyle={{ marginTop: 16 }}>
                    <Window style={{ paddingHorizontal: 16 }}>
                        <Text style={styles.title}>Поиск по тексту</Text>
                        <TextInput onChangeText={(value) => setSearch(value)} value={search} style={styles.input} placeholder='Введите текст' placeholderTextColor='#667085' />
                    </Window>
                </ScrollView>
                <Window>
                    <View style={styles.footer}>
                        <TouchableOpacity onPress={onReset} style={styles.clearButton}>
                            <Text style={styles.clearButtonText}>Сбросить</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={onSubmit} style={styles.button}>
                            <Text style={styles.buttonText}>Применить</Text>
                        </TouchableOpacity>
                    </View>
                </Window>
            </>
        )
    }

    return null
}

const styles = StyleSheet.create({
    container: {
        flex: 1
    },
    title: {
        fontSize: 16,
        fontWeight: '500',
        marginBottom: 8
    },
    input: {
        height: 44,
        borderWidth: 1,
        borderColor: '#D0D5DD',
        borderRadius: 4,
        paddingHorizontal: 10,
        flexDirection: 'row'
    },
    line: {
        marginVertical: 12
    },
    footer: {
        borderTopWidth: 1,
        borderColor: '#D0D5DD',
        padding: 16
    },
    clearButton: {
        height: 46,
        borderWidth: 1,
        borderColor: '#101828',
        borderRadius: 6,
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 12
    },
    clearButtonText: {
        fontSize: 16,
        fontWeight: '500',
        color: '#101828'
    },
    button: {
        justifyContent: 'center',
        alignItems: 'center',
        height: 48,
        borderRadius: 8,
        backgroundColor: '#101828',
    },
    buttonText: {
        color: '#FCFCFD',
        fontWeight: '500'
    }
})

export default FilterScreen